<template>
<div class="electrical">
  <div
      class="electrical-img"
      :style="bgImg"
  >
    <h2
        class="title"
        data-aos="fade-up"
        data-aos-offset="200"
        data-aos-delay="50"
        data-aos-duration="500"
    >{{title}}</h2>
  </div>
  <ProductComponent
      :categories="categoryData"
      v-if="title === 'Smart Home Electrical'"
  />
  <SubComponentProduct
      v-if="title !== 'Smart Home Electrical'"
      :categories="subProductData"
  />
  <Footer/>
</div>
</template>

<script>
import Footer from "@/components/Nav/Footer";
import ProductComponent from "@/components/ProductComponent";
import SubComponentProduct from "@/components/SubComponentProduct";
export default {
  name: "Electrical",
  components: {SubComponentProduct, ProductComponent, Footer},
  data(){
    return{
      title: 'Smart Home Electrical',
      subProductData: [],
      categoryData: [
        {
          name: 'Smart Outlets + Switches',
          subcategory: ['Smart Plugs', 'Smart Power Strips', 'Smart Wall Sockets', 'Smart Light Switches', 'Smart Light Dimmers']
        },
        {
          name: 'Smart Lighting',
          subcategory: ['Smart Light Sockets', 'Smart Bulbs', 'Smart LED Light Strips', 'Smart Outdoor Lights']
        },
      ],
      bgImg: {
        backgroundImage: `url(${require('@/assets/images/img/electrical.png')})`
      }
    }
  },
  mounted() {
    window.scrollTo(0,0)
    this.title =  this.subcategory_title || this.title
  },
  computed: {

  },
  watch:{

  },
  methods: {

  },
}
</script>

<style lang="scss">
@import "@/assets/scss/_vars.scss";
.electrical{
  .electrical-img{
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
    background-position: top center;
    padding: 414px 0 77px 100px;
    .title{
      color: $white;
    }
  }
}
</style>
